import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/app/globals.css");
;
import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/app/cookieconsent.css");
;
import(/* webpackMode: "eager", webpackExports: ["AOSInit"] */ "/var/www/baansindhorn.com-docker/components/aos.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/node_modules/@fancyapps/ui/dist/fancybox/fancybox.css");
;
import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/node_modules/next/font/google/target.css?{\"path\":\"utils/font.ts\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"variable\":\"--font-poppins\",\"display\":\"swap\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/node_modules/next/font/google/target.css?{\"path\":\"utils/font.ts\",\"import\":\"Playfair_Display\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"variable\":\"--font-playfair\",\"display\":\"swap\"}],\"variableName\":\"playfair\"}");
;
import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/node_modules/vanilla-cookieconsent/dist/cookieconsent.css");
;
import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/node_modules/swiper/modules/effect-fade.css");
;
import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/node_modules/swiper/modules/pagination.css");
;
import(/* webpackMode: "eager" */ "/var/www/baansindhorn.com-docker/node_modules/swiper/modules/scrollbar.css");
